<template>
    <div class="component account-error">
        <div class="body">
            <div class="title">
                Atenție!
            </div>
            <div class="errors">
                <div class="error">
                    {{ errors[0] }}
                </div>
            </div>
            <div class="contact">
                <div class="item">
                    <div class="label">Administraor:</div>
                    <div class="value">+40 753 55 44 11</div>
                </div>
                <div class="item">
                    <div class="label">Relații cu clienții:</div>
                    <div class="value">+40 752 20 81 20</div>
                </div>
                <div class="item email">
                    <div class="label">Email:</div>
                    <div class="value">contact@startaxiapp.com
                    </div>
                </div>
            </div>
            <div class="actions">
            	<v-btn @click="$emit('close')" color="primary" class="submit-form w-100" size="x-large" rounded="xl" variant="flat">OK</v-btn>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
@import 'AccountError.scss';
</style>
<script>
export default {
    name: 'AccountError',
    props: ['errors'],
}
</script>