<template>
    <div class="component order-form" data-aos="fade-right">
        <div class="title">{{ title }}</div>
        <div class="form">
            <v-row>
                <!-- Addresses -->
                <v-col cols="12">
                    <autocomplete-address @focus="handleAddressFocus('pickup')" @clickMap="$emit('clickMap', $event)" @select="handleSelectPickup" :showMap="true" apiType="src" type="pickup" ref="pickupAddress" label="Caută adresă imbarcare" />
                </v-col>
                <v-col class="extraDestinations" cols="12" v-for="(destination, index) in extraDestinations" :key="index">
                    <autocomplete-address @focus="handleAddressFocus('destination')" 
                    @clickMap="$emit('clickMap', $event)" 
                    @select="handleSelectExtraDestination($event, index)" 
                    :showMap="true" 
                    apiType="dst" 
                    type="extraDestination" 
                    :extraDestinationIndex="index"
                    ref="extraDestinationAddress" 
                    label="Caută adresă intermediară" 
                    />
                    <div @click="handleRemoveExtraDestination(index)" class="remove">
                        <v-icon>mdi-minus</v-icon>
                    </div>
                </v-col>
                <v-col cols="12">
                    <autocomplete-address @focus="handleAddressFocus('destination')" @clickMap="$emit('clickMap', $event)" @select="handleSelectDestination" :showMap="true" apiType="dst" type="destination" ref="destinationAddress" label="Caută adresă destinație" />
                </v-col>
                <v-col cols="12" v-if="extraDestinations.length < maxExtraDestinations">
                    <a @click="addExtraDestination" href="#" class="add-destination">
                        <v-icon>mdi-plus</v-icon> Adaugă destinație
                    </a>
                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="order.alternate_client_name" hide-details placeholder="Nume pasager" variant="solo"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field v-model="order.alternate_phonenumber" hide-details placeholder="Număr telefon" variant="solo"></v-text-field>
                </v-col>
                <!-- <v-col cols="12">
                    <v-text-field v-model="order.details" hide-details placeholder="Detalii cursă" variant="solo"></v-text-field>
                </v-col> -->
                <v-col cols="12">
                    <v-text-field v-model="order.details" hide-details placeholder="Detalii pentru șofer" variant="solo"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <select-payment-method @updated="handleSelectPaymentMethod" />
                </v-col>
                <v-col cols="6">
                    <v-select placeholder="Opțiuni" v-model="order.options" hide-details :items="allOptions" item-value="id" multiple variant="solo">
                        <template v-slot:selection="{ props, item }">
                            {{ item?.title.substr(0,3) }}
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" v-if="info" data-aos="fade-in">
                    <div class="info-box">
                        <v-row>
                            <v-col cols="2" class="icon">
                                <img src="@/assets/images/car.png" alt="" style="width: 43px;">
                            </v-col>
                            <v-col class="distance">
                                Distanță <span class="sbold">&nbsp; {{ parseFloat(info.ride.distance / 1000).toFixed(1)}} &nbsp;</span> <span class="light">km</span>
                            </v-col>
                            <v-col cols="4" class="price">
                                <span class="sbold">{{info.min}}-{{info.max}}</span> <span class="light">RON</span>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col cols="12">
                    <v-btn @click="sendOrder" :color="orderIsValid && orderStore.processingOrders < 3 ? 'primary' : 'dark'" class="submit-form w-100" size="x-large" rounded="xl" :variant="(orderIsValid == true) && orderStore.processingOrders < 3 ? 'flat' : 'outlined'" :disabled="!orderIsValid || orderStore.processingOrders >= 3">Comandă Acum</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<style scoped lang="scss">
@import 'OrderForm.scss';
</style>
<script>
import AutocompleteAddress from '@/components/forms/AutocompleteAddress/AutocompleteAddress.vue';
import SelectPaymentMethod from '@/components/forms/SelectPaymentMethod/SelectPaymentMethod.vue';
import { useOrderStore } from '@/stores/orderStore';
import { mapStores } from 'pinia';
export default {
    props: ['info'],
    name: "OrderForm",
    components: {
        AutocompleteAddress,
        SelectPaymentMethod,
    },
    data() {
        return {
            maxExtraDestinations: 3,
            title: 'Unde ești?',
            extraDestinations: [
            ],
            order: {
                alternate_client_name: null,
                alternate_phonenumber: null,
                driver_details: null,
                pickup: null,
                destination: null,
                details: null,
                options: [],
                payment_method: null,
            },
            allOptions: [{
                    id: 1,
                    title: "AC",
                },
                {
                    id: 3,
                    title: "Bagaje",
                },
                {
                    id: 4,
                    title: "Rovinietă"
                }
            ]
        }
    },
    computed: {
        orderIsValid() {
            return (this.order.pickup != null);
        },
        ...mapStores(useOrderStore),
    },
    methods: {
        async sendOrder() {
            try {

                await this.orderStore.create(this.order, this.extraDestinations);
                this.$toast.success("Comandă a fost plasată");

                //this.$refs.pickupAddress.selectAddress(null);
                this.$refs.destinationAddress.selectAddress(null);
                this.order.alternate_client_name = null;
                this.order.alternate_phonenumber = null;
                this.order.driver_details = null;
                this.order.details = null;
                this.order.options = [];
                
                this.$emit('orderCreated');

            } catch (error) {
                console.log(error);
                this.$toast.error("Eroare plasare comandă");
                if (error.response.data.message.length > 0) {
                    this.$emit('errors', error.response.data.message);
                }
            }
        },
        async handleAddressFocus(type) {
            if (type == 'pickup') {
                this.title = 'Unde ești?';
            } else if (type == 'destination') {
                this.title = 'Unde mergi?';
            }
        },
        handleSelectPaymentMethod(method) {
            this.order.payment_method = method.type;
        },
        handleSelectPickup(place) {
            this.order.pickup = place;
            if (place && place.details) {
                this.order.details = place.details;
            } else {
                this.order.details = null;
            }
            this.$emit('selectPickup', place);
        },
        selectPickup(place) {
            this.order.pickup = place;
            this.$refs.pickupAddress.selectAddress(place);
        },
        handleSelectDestination(place) {
            this.order.destination = place;
            this.$emit('selectDestination', place);
        },
        handleSelectExtraDestination(place, index) {
            this.extraDestinations[index] = place;
            this.$emit('selectExtraDestination', {
                location: place,
                index: index,
            });
        },
        async handleRemoveExtraDestination(index) {
            this.$emit('selectExtraDestination', {
                location: null,
                index: index,
            });
            this.extraDestinations.splice(index, 1);
            // Fix for weird vue reactivity bug
            for (var i = 0; i < this.extraDestinations.length; i++) {
                let extraDestination = this.extraDestinations[i];
                console.log(extraDestination);
                if(JSON.stringify(extraDestination) == '{}') {
                    this.$refs.extraDestinationAddress[i].selectAddress(null);
                } else {
                    this.$refs.extraDestinationAddress[i].selectAddress(extraDestination);    
                }
                
            }
        },
        selectDestination(place) {
            this.order.destination = place;
            this.$refs.destinationAddress.selectAddress(place);
        },
        selectExtraDestination(place, index) {
            this.extraDestinations[index] = place;
            this.$refs.extraDestinationAddress[index].selectAddress(place);
        },
        addExtraDestination() {
            this.extraDestinations.push({});
        }
    }
}
</script>