<template>
    <div class="view orders-history" data-aos="fade">
        <v-container class="history container">
            <div class="orders">
                <v-row align-center>
                    <v-col cols="12" v-for="order in orders" data-aos="fade-in">
                        <v-card class="order">
                            <div class="status d-none d-sm-block">
                                {{ order.status }}
                            </div>
                            <div class="title">
                                {{ order.address_id.street_name }} {{ order.address_id.street_number }},<br>
                                {{ order.address_id.city}}, {{ order.address_id.country }}
                            </div>
                            
                            <div class="time">
                                {{ $moment.format(order.order_date, 'DD MMMM YYYY, hh:mm') }}
                            </div>
                            <div class="status d-block d-sm-none" style="padding-top: 15px;">
                                {{ order.status }}
                            </div>
                            <div class="details">
                                <div class="left">
                                    <div class="label">Detalii Comandă</div>
                                </div>
                                <div class="right">
                                    {{ order.driver_details.firstname }}, {{ order.driver_details.taxi_firm }}, {{ order.driver_details.car_model }}
                                </div>
                            </div>
                            <div class="address">
                                <div class="pickup" v-if="order.address_id">
                                    <div class="icon">
                                        <div class="line" v-if="order.destination_address"></div>
                                        <img src="@/assets/images/history_pickup.png" alt="">
                                    </div>
                                    <div class="label">Ridicare</div>
                                    <div class="value">{{ order.address_id.street_name }} {{ order.address_id.street_number }}</div>
                                </div>
                                <div class="dropoff" v-if="order.destination_address">
                                    <div class="icon">
                                        <img src="@/assets/images/history_dropoff.png" alt="">
                                    </div>
                                    <div class="label">Destinație</div>
                                    <div class="value">{{ order.destination_address.street_name }} {{ order.destination_address.street_no }}</div>
                                </div>
                            </div>
                            <div class="navigation">
                                <router-link class="nolink" :to="'/app/history/' + order.id">
                                    <v-btn class="more" color="dark" variant="text">Detalii</v-btn>
                                </router-link>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
            <div class="loading" v-if="loading">
                <v-progress-circular :size="50" width="6" indeterminate color="primary"></v-progress-circular>
            </div>
        </v-container>
    </div>
</template>
<style lang="scss" scoped>
@import 'OrdersHistoryView.scss';
</style>
<script>
export default {
    name: 'OrdersHistoryView',
    computed: {},
    data() {
        return {
            orders: [],
            data: null,
            loading: false,
            finished: false,
        }
    },
    async mounted() {
        await this.getData(1);
        let self = this;
        $(window).scroll(() => {
            if (parseInt($(window).scrollTop() + $(window).height()) == parseInt($(document).height())) {
                if (!this.loading) {
                    self.nextPage();
                }
            }
        });
    },
    methods: {
        async nextPage() {
            if (this.data.current_page < this.data.last_page) {
                this.getData(this.data.current_page + 1);
            }
        },
        async getData(page) {
            try {
                this.loading = true;
                let response = await axios.post('clients/get-orders?page=' + page);
                if (response.data.status == true) {
                    this.data = response.data.data.orders;
                    this.orders = this.orders.concat(this.data.data);
                } else {
                    this.$toast.error("Eroare la incărcarea comenzilor.");
                }
            } catch (error) {
                console.log(error);
                this.$toast.error("Eroare la incărcarea comenzilor.");
            }
            this.loading = false;
        }
    },
}
</script>