<template>
    <div class="view order" data-aos="fade-right">
        <!-- Desktop Layout -->
        <div class="live" :class="{ 
                'map-select-pickup': mapMode != 'free',
                'has-orders': orderStore.hasOrders, 
                'mobile-tab-order': mobileTab == 'order',
                'mobile-tab-orders': mobileTab == 'orders',
            }">
            <div class="mobile-navigation"> 
                <div class="items">
                    <div @click="mobileTab = 'order'" class="item" :class="{'active': mobileTab == 'order'}">
                        Comandă TAXI
                    </div>
                    <div @click="mobileTab = 'orders'" class="item" :class="{'active': mobileTab == 'orders'}">
                        Comenzi Active
                        <v-badge v-if="orderStore.hasOrders" text-color="#FFCE09" color="#363B4B" :content="orderStore.orders.length" offset-y="-15" offset-x="-15"></v-badge>
                    </div>
                </div>
            </div>
            <div class="order-zone custom-scroll">
                <order-form 
                v-if="!errors" 
                @errors="handleCreateOrderErrors" 
                @clickMap="handleSelectFromMap" 
                :info="info" ref="orderForm" 
                @selectPickup="handleSelectPickup" 
                @selectDestination="handleSelectDestination" 
                @selectExtraDestination="handleSelectExtraDestination"
                @orderCreated="mobileTab = 'orders'" />
                <account-error @close="errors = null" :errors="errors" v-if="errors" />
            </div>
            <div class="active-orders orders-zone custom-scroll">
                <active-order :order="order" v-for="order in orderStore.allOrders" :key="order.id" />
            </div>
            <div class="map-zone" :class="mapMode">
                <GMapMap 
                ref="mainMap" 
                :center="{lat: center.lat, lng: center.lng}" 
                :zoom="zoom" 
                :options="{
                    mapTypeId: 'roadmap',
                    mapId: '711d7654833874ad',
                    mapTypeControl: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                }">
                    <GMapMarker v-if="pickup" icon="/images/marker_pickup.png" :position="{lat: pickup.latitude, lng: pickup.longitude}" :draggable="true" :clickable="true" @dragend="handlePickupDrag" />
                    <GMapMarker v-if="destination" icon="/images/marker_destination.png" :position="{lat: destination.latitude, lng: destination.longitude}" :draggable="true" :clickable="true" @dragend="handleDestinationDrag" />

                    <GMapMarker v-for="(destination, index) in extraDestinations" icon="/images/marker_destination.png" :position="{lat: destination.latitude, lng: destination.longitude}" :draggable="true" :clickable="true" @dragend="handleExtraDestinationDrag($event, index)" />



                    <GMapPolyline ref="mapLine" v-if="info" :path="info.path" />
                </GMapMap>
                <div class="main-map-overlay" v-if="mapMode != 'free'">
                    <div class="close" @click="handleCloseSelectFromMap">
                        <v-icon size="32px">mdi-close</v-icon>
                    </div>
                    <img class="center-icon" src="/images/marker_pickup.png" alt="" v-if="mapMode == 'pickup'">
                    <img class="center-icon" src="/images/marker_destination.png" alt="" v-if="mapMode == 'destination'">
                    <img class="center-icon" src="/images/marker_destination.png" alt="" v-if="mapMode.type && mapMode.type == 'extraDestination'">
                </div>
                <div class="confirm-pickup" v-if="mapMode != 'free'">
                    <div class="label">
                        Alege locul de {{ (mapMode == 'pickup') ? 'preluare' : 'destinație'}}
                    </div>
                    <div class="address" v-if="tempPlace">
                        {{ tempPlace.street_name }} {{ tempPlace.street_no }}
                    </div>
                    <div class="actions">
                        <v-btn @click="confirmMapPoint" color="primary" class="submit-form" size="x-large" rounded="xl" variant="flat">Confirmă</v-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import 'LiveView.scss';
</style>
<script>
import { useAuthStore } from '@/stores/authStore.js';
import { useOrderStore } from '@/stores/orderStore.js';
import { mapStores } from 'pinia';
import OrderForm from '@/components/live/OrderForm/OrderForm.vue';
import ActiveOrder from '@/components/live/ActiveOrder/ActiveOrder.vue';
import AccountError from '@/components/live/AccountError/AccountError.vue';
import starTaxiPlaces from '@/utils/starTaxiPlaces.js';
import socket from '@/emitters/socketBus.js';
import helper from '@/utils/helper.js';

if (typeof(Number.prototype.toRad) === "undefined") {
    Number.prototype.toRad = function() {
        return this * Math.PI / 180;
    }
}

//-- Define degrees function
if (typeof(Number.prototype.toDeg) === "undefined") {
    Number.prototype.toDeg = function() {
        return this * (180 / Math.PI);
    }
}

export default {
    name: 'OrderView',
    computed: {
        ...mapStores(useAuthStore, useOrderStore),
    },
    data() {
        return {
            errors: null,
            mobileTab: 'order',
            info: null,
            pickup: null,
            destination: null,
            extraDestinations: [],
            center: {
                lat: 44.426783,
                lng: 26.102449,
            },
            zoom: 15,
            mapMode: 'free',
            mapObject: null,
            tempPlace: null,
        }
    },
    watch: {
        'mobileTab': {
            handler() {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }
    },
    components: {
        OrderForm,
        ActiveOrder,
        AccountError,
    },
    async beforeUnmount() {
        socket.off('order-offer');
        socket.off('order-status-change');
    },
    async mounted() {

        this.center.lat = this.authStore.location.lat;
        this.center.lng = this.authStore.location.lng;

        await this.orderStore.fetchOrders();
    },
    methods: {
        handleCreateOrderErrors(errors) {
            this.errors = errors;
        },
        getDistance(lat1, lon1, lat2, lon2) {
            function toRad(Value) {
                return Value * Math.PI / 180;
            }
            var R = 6371; // km
            var dLat = toRad(lat2 - lat1);
            var dLon = toRad(lon2 - lon1);
            var lat1 = toRad(lat1);
            var lat2 = toRad(lat2);

            var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var d = R * c;
            return d;
        },
        getMiddlePoint(lat1, lng1, lat2, lng2) {

            //-- Longitude difference
            var dLng = (lng2 - lng1).toRad();

            //-- Convert to radians
            lat1 = lat1.toRad();
            lat2 = lat2.toRad();
            lng1 = lng1.toRad();

            var bX = Math.cos(lat2) * Math.cos(dLng);
            var bY = Math.cos(lat2) * Math.sin(dLng);
            var lat3 = Math.atan2(Math.sin(lat1) + Math.sin(lat2), Math.sqrt((Math.cos(lat1) + bX) * (Math.cos(lat1) + bX) + bY * bY));
            var lng3 = lng1 + Math.atan2(bY, Math.cos(lat1) + bX);

            //-- Return result                
            return {
                latitude: lat3.toDeg(),
                longitude: lng3.toDeg(),
            }
        },
        centerMapMarkers() {
            let middlePoint = this.getMiddlePoint(this.pickup.latitude, this.pickup.longitude, this.destination.latitude, this.destination.longitude);
            let distance = this.getDistance(this.pickup.latitude, this.pickup.longitude, this.destination.latitude, this.destination.longitude);            
            let zoom =  Math.log2(40000 / distance) + 1;
            this.center.lat = middlePoint.latitude;
            this.center.lng = middlePoint.longitude;
            this.zoom = zoom;
        },
        async confirmMapPoint() {
            if (this.mapMode == 'pickup') {
                this.$refs.orderForm.selectPickup(this.tempPlace);
            } else if (this.mapMode == 'destination') {
                this.$refs.orderForm.selectDestination(this.tempPlace);
            } else if (this.mapMode.type && this.mapMode.type == 'extraDestination') {
                this.$refs.orderForm.selectExtraDestination(this.tempPlace, this.mapMode.index);
            }
            this.mapMode = 'free';
            this.tempPlace = null;
        },
        handleCloseSelectFromMap() {
            this.mapMode = 'free';
            this.tempPlace = null;
        },
        async handleSelectFromMap(type) {
            if (this.mapObject == null) {
                this.mapObject = this.$refs.mainMap.$mapObject;
            }

            this.mapObject.addListener('dragend', this.handleMapCenterChange);

            let place = await starTaxiPlaces.reverse({
                lat: this.mapObject.center.lat(),
                lng: this.mapObject.center.lng(),
            });

            this.tempPlace = place;
            this.mapMode = type;
        },
        async handleMapCenterChange() {
            if (this.mapMode == 'free') {
                return;
            }
            let place = await starTaxiPlaces.reverse({
                lat: this.mapObject.center.lat(),
                lng: this.mapObject.center.lng(),
            });

            this.tempPlace = place;
        },
        // Pickup address changed
        async handleSelectPickup(location) {
            this.pickup = location;
            if (location) {
                this.center.lat = location.latitude;
                this.center.lng = location.longitude;
            }
            await this.getRideInfo();
        },
        // Destination address changed
        async handleSelectDestination(location) {
            this.destination = location;
            if (location) {
                this.center.lat = location.latitude;
                this.center.lng = location.longitude;
            }
            await this.getRideInfo();
        },
        // Extra destination address changed
        async handleSelectExtraDestination(event){       
            let location = event.location;
            let index = event.index;
            if (location) {
                this.extraDestinations[index] = location;
                this.center.lat = location.latitude;
                this.center.lng = location.longitude;
            } else {
                this.extraDestinations.splice(index, 1);
            }
            //await this.getRideInfo();
        },
        // Pickup map point change
        async handlePickupDrag(event) {
            let place = await starTaxiPlaces.reverse({
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            })
            this.$refs.orderForm.selectPickup(place);
        },
        // Destination map point change
        async handleDestinationDrag(event) {
            let place = await starTaxiPlaces.reverse({
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            })
            this.$refs.orderForm.selectDestination(place);
        },
        // Extra destination map point change
        async handleExtraDestinationDrag(event, index) {
            let place = await starTaxiPlaces.reverse({
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            })
            this.$refs.orderForm.selectExtraDestination(place, index);
        },
        // Get ride details
        async getRideInfo() {
            this.info = null;
            if (!this.pickup || !this.destination) {
                return;
            }

            try {
                let response = await axios.post('clients/ride/info', {
                    src_lat: this.pickup.latitude,
                    src_lng: this.pickup.longitude,
                    dst_lat: this.destination.latitude,
                    dst_lng: this.destination.longitude,
                });

                if (response.data.status != true) {
                    this.$toast.error("Eroare la incărcarea comenzilor.");
                    return;
                }

                response.data.data.path = helper.decodePolyline(response.data.data.path_full);
                this.info = response.data.data;
                await this.$nextTick();
                this.centerMapMarkers();
            } catch (error) {
                console.log(error);
                this.$toast.error("Eroare la incărcarea comenzilor.");
            }

        },
    }
}
</script>