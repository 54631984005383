try {

    // Load global runtime libs
    window._ = require('lodash');
    window.$ = window.jQuery = require('jquery');
    window.jQuery = require('jquery');

    // Axios Config
    window.axios = require('axios');
    //axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.common['Accept'] = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.baseURL = 'https://apinew.startaxiapp.com/api/v2/';
    
    //axios.defaults.timeout = 15000;

    const token = localStorage.getItem('auth_token');
    if (token) {
        axios.defaults.headers.common['api-token'] = token;
    }

    axios.interceptors.response.use(
        function(response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            return response;
        },
        function(error) {
            if(error.response) {
                // Too Many Requests
                // Error 429
                if (error.response.status === 429) {
                    console.log("too many requests");
                }
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // 401 Unauthorized
                if (error.response.status === 401) {
                    localStorage.removeItem("auth_token");
                    localStorage.removeItem("auth_user");
                    window.location.reload();
                }

                return Promise.reject(error);
            }
            
            //
        },
    );

} catch (e) {
    console.log(e);
}