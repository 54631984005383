import { decode as decodePolyline } from "@googlemaps/polyline-codec";
export default {
    // Get order status name by number
    getStatusName(statusNumber) {
        switch (statusNumber) {
            case 1:
                return "Căutare Șofer"
                break;

            case 3:
                return "Șofer Acceptat"
                break;

            case 4:
                return "În drum spre destinație";
                break;

            case 5:
                return "Finalizată";
                break;

            case -4:
                return "Neprezentare Client";
                break;

            case -5:
                return "Șoferul nu a venit";
                break;

            case -6:
                return "Anulată";
                break;

            case -21:
                return "Nu am găsit șofer";
                break;
        }
    },
    // Decode google polyline to point array
    decodePolyline(polyString) {
        try {
            let polyArray = decodePolyline(polyString, 5);
            return polyArray.map(e => {
                return {
                    lat: e[0],
                    lng: e[1]
                }
            })
        } catch (error) {
            console.log(error);
            return [];
        }
    },
}