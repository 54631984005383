<template>
    <div class="component active-order">
        <chat-order-overlay @back="view = 'order'" :order="order" v-if="view == 'chat'" />
        <cancel-order-overlay @showPhone="showPhoneDialog = true" :order="order" @confirm="cancelOrder" @abort="view = 'order'" v-if="view == 'cancel'" />
        <report-driver-overlay :order="order" @confirm="orderStore.fetchOrders()" @abort="view = 'order'" v-if="view == 'report'" />
        <v-card class="order w-100" :class="order.order.order_details.status" v-if="view == 'order'" flat>
            <v-card-item>
                <v-card-title>
                    <v-progress-linear class="loader rounded" height="6px" indeterminate color="primary" v-if="order.order.order_details.status_numeric == 1"></v-progress-linear>
                    <div class="title">
                        <span v-if="order.order.order_details.status_numeric == 1 && order.offers.length == 0">
                            Căutăm șoferi...
                        </span>
                        <span v-else-if="order.order.order_details.status_numeric == 1 && !showOffers">
                            Căutăm șoferi...
                        </span>
                        <span v-else-if="order.order.order_details.status_numeric == 1 && order.offers.length > 0 && showOffers">
                            Ajunge in {{ order.offers[0].estimated_arrival_minutes }} minute
                        </span>
                        <span v-else-if="order.order.order_details.status_numeric == 3">
                           {{ driverCountdown }}
                        </span>                        
                        <span v-else-if="order.order.order_details.status_numeric == -21">
                            Nu am găsit șofer
                        </span>
                        <span v-else>
                            {{ order.order.order_details.status }}
                        </span>
                        <a v-if="order.order.order_details.status_numeric == 1" @click="cancelOrder" class="cancel nolink" href="#">Anulează &nbsp;<span class="timer" v-if="!order.offer && countdown">({{ countdown }}s)</span></a>
                        <a v-else-if="order.order.order_details.status_numeric == 3" @click="view = 'cancel'" class="cancel nolink" href="#">Anuleaza</a>
                        <a v-else-if="order.order.order_details.status_numeric == -4" @click="orderStore.removeCachedOrder(order)" class="cancel nolink" href="#">Închide</a>
                        <!-- <json-dump :data="order" /> -->
                    </div>
                </v-card-title>
            </v-card-item>
            <v-card-text>
                <div class="address" :class="{'has-destination': order.order.dst_address_details}">
                    <div class="payment-method-icon">
                        <img v-if="order.order.order_details.payment_method == 1" src="@/assets/svg/icon_cash.svg" alt="">
                        <img v-if="order.order.order_details.payment_method == 2" src="@/assets/svg/icon_card.svg" alt="">
                        <img v-if="order.order.order_details.payment_method == 4" src="@/assets/svg/icon_invoice.svg" alt="">
                    </div>
                    <div class="pickup" v-if="order.order.address_details">
                        <div class="icon">
                            <div class="line" v-if="order.order.dst_address_details"></div>
                            <img src="@/assets/images/history_pickup.png" alt="">
                        </div>
                        <div class="text">
                            <label>Ridicare</label><br>
                            {{ order.order.address_details.street_name }} {{ order.order.address_details.street_number }}
                        </div>
                    </div>
                    <div class="destination" v-if="order.order.dst_address_details">
                        <div class="icon">
                            <img src="@/assets/images/history_dropoff.png" alt="">
                        </div>
                        <div class="text">
                            <label>Destinație</label><br>
                            {{ order.order.dst_address_details.street_name }} {{ order.order.dst_address_details.street_number }}
                        </div>
                    </div>
                </div>
                <div class="driver-canceled" v-if="order.order.order_details.status_numeric == -4">
                    <div class="box">
                        <div class="text">
                            Șoferul a anulat<br>
                            comanda!
                        </div>
                        <div class="subtext">
                            Poți retransmite comanda<br>
                            sau să raportezi un abuz
                        </div>
                        <img src="@/assets/images/car_cancel.png" alt="" class="icon">
                    </div>
                    <div class="actions" style="margin-top: 20px;">
                        <v-row>
                            <!-- <v-col cols="6">
                                <v-btn @click="resendOrder()" color="dark" class="accept w-100" variant="text">
                                    <v-icon style="margin-right: 10px;">mdi-refresh</v-icon> Retrimite
                                </v-btn>
                            </v-col> -->
                            <v-col>
                                <v-btn @click="view = 'report'" color="gray" class="deny w-100" variant="text">
                                    <v-icon style="margin-right: 10px;">mdi-cancel</v-icon> Raport Abuz
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
                <div class="order-expired" v-if="order.order.order_details.status_numeric == -21">
                    <div class="actions" style="margin-top: 20px;">
                        <v-row>
                            <v-col cols="6">
                                <v-btn @click="resendOrder(true)" color="dark" class="accept w-100" variant="text" >
                                    <v-icon style="margin-right: 10px;">mdi-refresh</v-icon> Retrimite  <span class="timer" v-if="resendTimeDifference"> ({{ resendTimeDifference }}s)</span>
                                </v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn @click="this.orderStore.removeCachedOrder(order)" color="gray" class="deny w-100" variant="text">
                                    <v-icon style="margin-right: 10px;">mdi-close</v-icon> Închide
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
                <div class="offers" v-if="
                    (showOffers || order.order.order_details.status_numeric != 1) &&
                    order.offers[0] &&
                    order.order.order_details.status_numeric != -4 &&
                    order.order.order_details.status_numeric != -21
                    ">
                    <div class="offer" v-for="offer in [order.offers[0]]">
                        <div class="driver">
                            <div class="image">
                                <img :src="offer.driver.profile_picture_url" alt="">
                            </div>
                            <div class="details">
                                <v-row class="ma-0">
                                    <v-col cols="6" class="pa-0" v-if="offer.driver.firstname">
                                        <div class="name">{{ offer.driver.firstname }}</div>
                                    </v-col>
                                    <v-col cols="6" class="pa-0" v-if="offer.driver.rating">
                                        <div class="rating"><img src="@/assets/images/rating_star.png" alt=""> {{ offer.driver.rating }}</div>
                                    </v-col>
                                    <v-col cols="6" class="pa-0" v-if="offer.driver.taxi_firm">
                                        <div class="firm">{{ offer.driver.taxi_firm }}</div>
                                    </v-col>
                                    <v-col cols="6" class="pa-0" v-if="offer.driver.price_per_km">
                                        <div class="pnpm rice">{{ offer.driver.price_per_km }} {{ offer.driver.pricing_measure_unit }}</div>
                                    </v-col>
                                    <v-col cols="6" class="pa-0" v-if="offer.driver.car_model">
                                        <div class="car">{{ offer.driver.car_model }}</div>
                                    </v-col>
                                    <v-col cols="6" class="pa-0" v-if="offer.driver.car_license_number">
                                        <div class="number">{{offer.driver.car_license_number}}</div>
                                    </v-col>
                                    <v-col cols="6" class="pa-0" v-if="offer.driver.call_sign">
                                        <div class="number">{{offer.driver.call_sign}}</div>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                        <!-- Actions: Offer -->
                        <div class="actions" v-if="order.order.order_details.status_numeric == 1">
                            <v-row>
                                <v-col cols="6">
                                    <v-btn @click="acceptOffer(offer)" color="dark" class="accept w-100" variant="text">
                                        Accept &nbsp;<span class="timer" v-if="countdown">({{ countdown }}s)</span>
                                    </v-btn>
                                </v-col>
                                <v-col cols="6">
                                    <v-btn @click="declineOffer(offer)" color="gray" class="deny w-100" variant="text" :disabled="order.offers.length < 2">
                                        Alt Sofer
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </div>
                <!-- Actions: Waiting for driver -->
                <div class="actions" v-if="order.order.order_details.status_numeric == 3 ">
                    <v-row>
                        <v-col>
                            <v-btn @click="handleOpenChat" color="dark" class="accept w-100" variant="text">
                                <v-icon>mdi-chat-outline</v-icon> &nbsp;
                                Chat <span class="timer" v-if="order.messages.filter(e => {return (!e.read) }).length > 0">&nbsp;({{order.messages.length}})</span>
                            </v-btn>
                        </v-col>
                        <v-col>
                            <a @click="showPhoneDialog = true">
                                <v-btn color="gray" class="deny w-100" variant="text">
                                    <v-icon>mdi-phone-outline</v-icon> &nbsp;
                                    Sună
                                </v-btn>
                            </a>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
        </v-card>
        <div class="dialogs">
            <v-dialog v-model="showPhoneDialog" width="300px">
                <v-card>
                    <v-card-text class="text-center">
                        {{ order.offers[0].driver.phone_no }}
                    </v-card-text>
                    <a :href="'tel:' + order.offers[0].driver.phone_no">
                        <v-btn color="#FFCE09" class="deny w-100">
                            <v-icon>mdi-phone-outline</v-icon> &nbsp;
                            Apelează
                        </v-btn>
                    </a>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>
<style lang="scss">
@import 'ActiveOrder.scss';
</style>
<script>
import { useOrderStore } from '@/stores/orderStore.js';
import { useAuthStore } from '@/stores/authStore.js';
import { mapStores } from 'pinia';
import CancelOrderOverlay from './components/CancelOrderOverlay/CancelOrderOverlay.vue';
import ChatOrderOverlay from './components/ChatOrderOverlay/ChatOrderOverlay.vue';
import ReportDriverOverlay from './components/ReportDriverOverlay/ReportDriverOverlay.vue';
export default {
    props: ['order'],
    name: "ActiveOrders",
    computed: {
        ...mapStores(useOrderStore, useAuthStore),
        driverIsHere() {
            if(this.order.order.order_details.status_numeric != 3) {
                return false;
            }
            let driverIsHereMessage = this.order.messages.find(e => {
                return e.type == "2";
            })
            return (driverIsHereMessage);
        }
    },
    data() {
        return {
            showOffers: false,
            showPhoneDialog: false,
            view: 'order',
            tickInterval: null,
            countdown: null,
            driverCountdown: null,
            resendCounter: 0,
            maxResends: 3,
            resendTimeDifference: null,
        }
    },
    components: {
        CancelOrderOverlay,
        ChatOrderOverlay,
        ReportDriverOverlay,
    },
    mounted() {
        this.handleTick();
        this.tickInterval = setInterval(() => {
            this.handleTick();
        }, 1000);
    },
    beforeUnmount() {
        clearInterval(this.tickInterval);
    },
    methods: {
        handleOpenChat() {
            this.order.messages.forEach(message => {
                message.read = true;
            });
            this.view = 'chat';
        },
        adjustClientTime(clientTime) {
            let timeDifference = this.authStore.timeDifference;
            clientTime.subtract(timeDifference, 'seconds');
            return clientTime;
        },
        handleTick() {
            // Looking for driver
            if (this.order.order.order_details.status_numeric == 1) {
                let now = this.adjustClientTime(this.$moment.moment(new Date())); // Get client time

                // Run display order timer
                let showOffersEnd = this.$moment.moment(this.order.order.order_details.order_date).add(7, 'seconds');
                let showOffersDiff = Math.ceil(this.$moment.moment.duration(now.diff(showOffersEnd)).asSeconds());
                if(showOffersDiff == 0) {
                    this.sortOffersByRating();
                    this.showOffers = true;
                }
                // Run cancel order timers
                let end = (this.order.offers[0]) ?
                    this.$moment.moment(this.order.offers[0].driver_response_time).add(20, 'seconds') : // driver response
                    this.$moment.moment(this.order.order.order_details.order_date).add(1, 'minutes'); // order creation

                console.log('end', end.format("YYYY-MM-DD HH:mm:ss"));

                let diff = parseInt(this.$moment.moment.duration(now.diff(end)).asSeconds());
                console.log('diff', diff);
                if (diff < 0) {
                    // Update timer
                    this.countdown = Math.abs(diff);
                } else {
                    // Timer expired
                    if(this.order.offers[0]) {
                        // Orders found but user didn't pick any
                        // cancel order
                        this.cancelOrder();
                    } else {
                        // No orders were found,
                        // expire order and retry
                        this.expireOrder();    
                    }
                }
            } 
            // Order expired, automatic retry
            else if(this.order.order.order_details.status_numeric == -21) {
                let now = this.adjustClientTime(this.$moment.moment(new Date()));
                let resendTime = this.$moment.moment(this.order.order.order_details.order_date)
                                             .add(1, 'minutes')
                                             .add(5, 'seconds');

                let resendTimeDifference =  parseInt(this.$moment.moment.duration(now.diff(resendTime)).asSeconds());

                // Need to resend
                if(resendTimeDifference < 0) {
                    if(this.order.retry_count <= 2 || !this.order.retry_count) {
                        this.resendTimeDifference = Math.abs(resendTimeDifference);
                    } else {
                        this.resendTimeDifference = null;
                    }
                } else {
                    if(this.order.retry_count <= 2 || !this.order.retry_count) {
                        this.resendOrder();
                        this.resendTimeDifference = 5;
                    } else {
                        this.resendTimeDifference = null;
                    }
                }

            }
            // Driver needs to get here
            else if(this.order.order.order_details.status_numeric == 3 && !this.driverIsHere) {
                let now = this.adjustClientTime(this.$moment.moment(new Date()));
                let driverArriveTime = this.$moment.moment(this.order.offers[0].driver_response_time);
                driverArriveTime = driverArriveTime.add(this.order.offers[0].estimated_arrival_minutes, 'minutes');
                let driverArriveDifference = parseInt(this.$moment.moment.duration(now.diff(driverArriveTime)).asMinutes());
                console.log(driverArriveDifference)
                if(driverArriveDifference < 0) {
                    this.driverCountdown = `Șoferul sosește în ${Math.abs(driverArriveDifference)}m`;
                } else {
                    this.driverCountdown = `Șoferul sosește în curând`;
                }
            }
            // Driver is already here
            else if(this.order.order.order_details.status_numeric == 3 && this.driverIsHere) {
                this.driverCountdown = 'Șoferul a ajuns';
            }
            // Other cases
            else {
                this.countdown = null;
                this.driverCountdown = null;
            }
        },
        sortOffersByRating() {
            this.order.offers = this.order.offers.sort(function(a, b) {
                return a.score - b.score;
            });
        },
        async resendOrder(resetCounter = false) {
            if(resetCounter) {
                this.order.retry_count = null;
            }
            await this.orderStore.resubmitOrder(this.order);

            /*let newOrder = this.order.order.order_details;
            if (this.order.order.address_details) {
                newOrder.pickup = this.order.order.address_details;
            }
            if (this.order.order.dst_address_details) {
                newOrder.destination = this.order.order.dst_address_details;
            }
            await this.orderStore.create(newOrder);

            setTimeout(async () => {
                await this.orderStore.fetchOrders();
            },1000);*/
            
        },
        async cancelOrder() {
            try {
                this.view = 'order';
                await this.orderStore.respond(this.order, null, -2);
            } catch (error) {
                console.log(error);
            }
        },
        async acceptOffer(offer) {
            try {
                await this.orderStore.respond(this.order, offer, 1);
            } catch (error) {
                console.log(error);
            }
        },
        async declineOffer(offer) {
            let firstOrder = this.order.offers.shift();
            this.order.offers.push(firstOrder);
            /*try {
                await this.orderStore.respond(this.order, offer, -1);
            } catch (error) {
                console.log(error);
            }*/
        },
        async expireOrder() {
            this.order.order.order_details.status_numeric = -21;
            this.orderStore.cacheOrder(this.order);
        }
    }
}
</script>