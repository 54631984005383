<template>
    <div id="app">
        <v-app>
            <v-main>
                <router-view />
            </v-main>
        </v-app>
    </div>
</template>
<script>
import { onMounted } from "@vue/runtime-core";
import AOS from "aos";
export default {
    name: 'StarTaxi WebApp',
    setup() {
        onMounted(() => {
            AOS.init({
                duration: 300,
                easing: 'ease',
                once: true,
                offset: 0,
                anchorPlacement: 'top',
            });
        });
    },
}
</script>