<template>
    <div class="view subview favorites">
        <div class="drivers" v-if="drivers">
            <div class="box driver" v-for="driver in drivers">
                <v-avatar :image="driver.profile_picture_url" />
                <div class="name">
                    {{ driver.firstname }} {{ driver.lastname }}
                </div>
                <div class="details">
                    {{ driver.taxi_firm }} {{ driver.car_model }}
                </div>
                <div class="actions">
                    <img @click="selected = driver; showDeleteModal = true;" src="@/assets/images/favorites_trash.png" alt="">
                </div>
            </div>
            <div class="box" v-if="drivers.length == 0">
                Nici un șofer blocat
            </div>
        </div>
        <div class="loading" v-else>
            <v-progress-circular :size="50" width="6" indeterminate color="primary"></v-progress-circular>
        </div>
        <v-dialog v-model="showDeleteModal" width="auto">
            <v-card class="deleteModal drivers">
                <v-card-text>
                    <span>Ești sigur ca vrei sa ștergi din lista de blocați<br> acest șofer?</span><br><br>
                    <v-avatar :image="selected.profile_picture_url" class="avatar" />
                    {{ selected.firstname }} {{ selected.lastname }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="" variant="text" @click="showDeleteModal = false;">
                        Anulează
                    </v-btn>
                    <v-btn class="danger" variant="text" @click="removeDriver(selected); showDeleteModal = false">
                        Șterge
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    name: "DriverBlacklistedView",
    data() {
        return {
            selected: null,
            showDeleteModal: false,
            drivers: null,
        }
    },
    async mounted() {
        await this.getDrivers();
    },
    methods: {
        async getDrivers() {
            try {
                this.addresses = null;
                let response = await axios.post('clients/get-blacklist-drivers');
                if (response.data.status != true) {
                    this.$toast.error("Eroare la încărcarea datelor");
                    return;
                }
                this.drivers = response.data.data.drivers;
            } catch (error) {
                this.$toast.error("Eroare la încărcarea datelor");
            }
        },
        async removeDriver(driver) {
            try {
                console.log(driver);
                let response = await axios.post('clients/remove-blacklisted-drivers', {
                    driver_ids: {
                        0: driver.driver_id,
                    }
                }, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                });
                if (response.data.status == false) {
                    this.$toast.error("Eroare la ștergerea șoferului");
                    return;
                }
                await this.getDrivers();
                this.$toast.success("Șoferul nu mai este blocat");
            } catch (error) {
                this.$toast.error("Eroare la ștergerea șoferului");
            }
        }
    }

}
</script>