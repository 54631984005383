<template>
    <div class="view app">
        <navbar />
        <div class="content" v-if='!loading'>
            <router-view />
        </div>
        <div class="app-loading" v-if="loading">
            <v-progress-circular :size="50" width="6" indeterminate color="primary" />
        </div>
    </div>
</template>
<script>
import Navbar from '@/components/navigation/Navbar/Navbar.vue';
import { useAuthStore } from '@/stores/authStore.js';
import { useOrderStore } from '@/stores/orderStore.js';
import { mapStores } from 'pinia';
import socketBus from '@/emitters/socketBus.js';
import browserDetect from 'browser-detect';
export default {
    name: "AppView",
    components: {
        Navbar,
    },
    computed: {
        ...mapStores(useAuthStore, useOrderStore),
    },
    data() {
        return {
            socket: null,
            loading: true,
        }
    },
    async mounted() {

        //console.log(this.$moment.format(Date.now(), "HH:mm"));

        await this.initUserLocation();
        await this.initDeviceSettings();
        this.initWebSockets();
    },
    methods: {
        async initDeviceSettings() {
            let browser = await browserDetect();
            let data = {
                reg_id: '',
                lng: 'ro',
                os_type: 5,
                device_type: browser.mobile.toString(),
                device_name: browser.name,
                os_version: browser.os,
                version_code: 200,
                version_name: '2.0',
                device_diag: window.innerHeight + ' x ' + window.innerWidth,
                api_token: this.authStore.token,
            };
            let response = await axios.post("/init/set-device-info", data);
            if(response.data.status !== true) {
                this.$toast.error("Eroare inițializare dispozitiv");
            }
            // Set user local time difference
            // Grab server time from headers after cors fix

            let serverTime = this.$moment.moment(response.headers.date);
            let localTime = this.$moment.moment();
            let timeDifference = this.$moment.moment.duration(localTime.diff(serverTime));
            this.authStore.setTimeDifference(timeDifference);

        },
        async initUserLocation() {
            await navigator.geolocation.getCurrentPosition(
                userLocation => {
                    this.authStore.setLocation({
                        lat: userLocation.coords.latitude,
                        lng: userLocation.coords.longitude,
                    });
                    this.loading = false;
                },
                error => {
                    this.$toast.error("Accesul la locatie a fost respins");
                    console.log(error);
                    this.loading = false;
                });
        },
        initWebSockets() {

            let wsUrl = 'wss://apinew.startaxiapp.com/ws-clients/?api-token=' + this.authStore.token;

            this.socket = new WebSocket(wsUrl);

            this.socket.onopen = (event) => {
                console.log('ws open', event);
            };

            this.socket.onclose = (event) => {
                console.log('ws closed', event);
                if (this.authStore.loggedIn) {
                    this.initWebSockets();
                }
            }

            this.socket.onerror = (error) => {
                console.log('ws error', error);
            }

            // Receive WS events
            this.socket.onmessage = (message) => {
                message = JSON.parse(message.data);
                console.log('ws message in', message);
                socketBus.emit(message.event, message);
                if (message.event == 'order-message') {
                    this.orderStore.receiveMessage(message);
                }
                if(message.event == 'order-offer') {
                    this.orderStore.addOffer(message);
                    /*setTimeout(() => {
                        this.orderStore.fetchOrders();
                    }, 1000);*/
                }
                if(message.event == 'order-status-change') {
                    this.orderStore.handleStatusChange(message);
                }
                if(message.event == 'forceDisconnect') {
                    this.authStore.softLogout();
                    this.$router.push('/login')
                }
            }

            // Send WS Event
            socketBus.on('message', (message) => {
                console.log('ws message out', message);
                this.socket.send(JSON.stringify(message));
            });

        }
    },
    beforeUnmount() {
        this.socket.close();
        socketBus.off('message');
    }
}
</script>
