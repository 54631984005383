<template>
    <div class="view subview details">
        <div class="title">
            Detalii cont
        </div>
        <div class="page">
            <!-- <div class="box box-image" v-if="details" data-aos="fade">
                <v-avatar color="surface-variant" size="80px"></v-avatar>
                <div class="text">
                    
                </div>
                <div class="actions">
                    
                </div>
            </div> -->
            <div class="box box-form" v-if="details" data-aos="fade">
                <form @submit="updateDetails">
                    <div class="fields">
                        <v-row>
                            <v-col class="field" cols="12" md="6">
                                <div class="label">Prenume</div>
                                <v-text-field ref="firstname" :rules="[() => !!details.firstname || '* Camp obligatoriu']" v-model="details.firstname" variant="solo"></v-text-field>
                            </v-col>
                            <v-col class="field" cols="12" md="6">
                                <div class="label">Nume</div>
                                <v-text-field ref="lastname" :rules="[() => !!details.lastname || '* Camp obligatoriu']" v-model="details.lastname" variant="solo"></v-text-field>
                            </v-col>
                            <v-col class="field" cols="12" md="6">
                                <div class="label">Email</div>
                                <v-text-field ref="email" :rules="[() => !!details.email || '* Camp obligatoriu']" v-model="details.email" variant="solo"></v-text-field>
                            </v-col>
                            <v-col class="field" cols="12" md="6">
                                <div class="label">Telefon</div>
                                <v-text-field ref="phone" :rules="[() => !!details.phone_no || '* Camp obligatoriu']" v-model="details.phone_no" variant="solo"></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="actions">
                        <v-btn @click="updateDetails" class="main" variant="text" :loading="loading">Salvează</v-btn>
                    </div>
                </form>
            </div>
            <div class="loading" v-else>
                <v-progress-circular :size="50" width="6" indeterminate color="primary"></v-progress-circular>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
@import "DetailsView.scss";
</style>
<script>
export default {
    name: "AccountDetailsView",
    data() {
        return {
            details: null,
            loading: false,
            valid: true,
        }
    },
    async mounted() {
        await this.getDetails();
    },
    methods: {
        async getDetails() {
            try {
                let response = await axios.get('clients/get-user-profile');
                if (response.data.status != true) {
                    this.$toast.error("Eroare la încărcarea datelor");
                    return;
                }
                this.details = response.data.data;
            } catch (error) {
                this.$toast.error("Eroare la încărcarea datelor");
            }
        },
        async updateDetails() {
            this.loading = true;
            this.valid = true;

            let keys = ['firstname', 'lastname', 'email', 'phone'];

            for (var i = 0; i < keys.length; i++) {
                let key = keys[i];
                let errors = await this.$refs[key].validate(true);
                if (errors.length > 0) {
                    this.valid = false;
                }
            }

            if (this.valid === false) {
                this.$toast.error("Te rugăm să completezi toate câmpurile obligatorii");
                this.loading = false;
                return;
            }


            try {
                // API field mappings
                this.details.phone_number = this.details.phone_no;
                let response = await axios.post('clients/update-user', this.details);
                if (response.data.status != true) {
                    this.$toast.error("Eroare la procesarea datelor");
                    return;
                }

                this.$toast.success("Detalii actualizate");
            } catch (error) {
                if (error.response && error.response.data) {
                    this.$toast.error(error.response.data.human_message[0]);
                } else {
                    this.$toast.error("Eroare la procesarea datelor");
                }
            }
            this.loading = false;
        }
    }
}
</script>