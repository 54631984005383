import { useAuthStore } from '@/stores/authStore.js';
import { v4 as uuidv4 } from 'uuid';

export default {
	apiPrefix: 'https://places.startaxiapp.com/api',
	async searchEmpty(type) {
		if(type == null) {
			type = 'src';
		}
		let authStore = useAuthStore();
		let uuid = uuidv4();
		let url = `${this.apiPrefix}/place/search/${uuid}/${type}/${authStore.location.lat},${authStore.location.lng}?input=`;
		let response = await axios.get(url);
		if(response.data.status == true) {
			return response.data.data;
		} else {
			return [];
			//throw 'Eroare rezolvare adresa';
		}
	},
	async search(query, type = 'src') {
		if(type == null) {
			type = 'src';
		}
		let authStore = useAuthStore();
		let uuid = uuidv4();
		let url = `${this.apiPrefix}/place/search/${uuid}/${type}/${authStore.location.lat},${authStore.location.lng}?input=${query}`;
		let response = await axios.get(url);
		if(response.data.status == true) {
			return response.data.data;
		} else {
			return [];
			//throw 'Eroare rezolvare adresa';
		}
	},
	async reverse(location) {
		let url = `${this.apiPrefix}/reverse/${location.lat},${location.lng}`;
		let response = await axios.get(url);
		if(response.data.status == true) {
			return response.data.data;
		} else {
			throw 'Eroare rezolvare adresa';
		}
	},
	async resolveUnkown(location) {
		let authStore = useAuthStore();
		let uuid = uuidv4();
		let url = `${this.apiPrefix}/place/details/${uuid}/${location.id}`;
		let response = await axios.get(url);
		if(response.data.status == true) {
			return response.data.data;
		} else {
			return [];
			//throw 'Eroare rezolvare adresa';
		}
	}
	
}