<template>
    <div class="component cancel-order-overlay" data-aos="fade">
        <img class="car" src="@/assets/images/car.png" alt="">
        <div class="title">
            Ești sigur că vrei<br>să anulezi cursa?
        </div>
        <div class="subtitle">Șoferul dumneavoastră este pe drum.</div>
        <div class="actions">
            <v-btn @click="$emit('confirm')" class="second yes" variant="text">
                Da
            </v-btn>
            <v-btn @click="$emit('abort')" class="main no" variant="text">
                Nu
            </v-btn>
            <a @click="$emit('showPhone')">
                <v-btn class="main call" variant="text">
                    <v-icon>mdi-phone-outline</v-icon> &nbsp;
                    Sună șofer
                </v-btn>
            </a>
        </div>
    </div>
</template>
<style lang="scss">
@import 'CancelOrderOverlay.scss';
</style>
<script>
export default {
    props: ['order'],
    name: "CancelOrderOverlay",
}
</script>