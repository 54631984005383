<template>
    <div class="view subview feedback">
        <div class="title">
            Feedback
        </div>
        <div class="page">
            <div class="box">
                <div class="rating">
                    <div class="label">
                        Evaluați Aplicția
                    </div>
                    <div class="stars">
                        <div class="star" :class="{'filled': newRating >= 1}" @click="newRating = 1">
                            <img class="empty" src="@/assets/images/star_empty.png" alt="">
                            <img class="full" src="@/assets/images/star_full.png" alt="">
                        </div>
                        <div class="star" :class="{'filled': newRating >= 2}" @click="newRating = 2">
                            <img class="empty" src="@/assets/images/star_empty.png" alt="">
                            <img class="full" src="@/assets/images/star_full.png" alt="">
                        </div>
                        <div class="star" :class="{'filled': newRating >= 3}" @click="newRating = 3">
                            <img class="empty" src="@/assets/images/star_empty.png" alt="">
                            <img class="full" src="@/assets/images/star_full.png" alt="">
                        </div>
                        <div class="star" :class="{'filled': newRating >= 4}" @click="newRating = 4">
                            <img class="empty" src="@/assets/images/star_empty.png" alt="">
                            <img class="full" src="@/assets/images/star_full.png" alt="">
                        </div>
                        <div class="star" :class="{'filled': newRating >= 5}" @click="newRating = 5">
                            <img class="empty" src="@/assets/images/star_empty.png" alt="">
                            <img class="full" src="@/assets/images/star_full.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <v-textarea v-model="message" placeholder="Scrie un comentariu" variant="solo" :counter="160" :maxLength="160" flat></v-textarea>
            <div class="actions">
                <v-btn @click="send" class="main" variant="text" :loading="loading">Trimite</v-btn>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
@import 'FeedbackView.scss';
</style>
<script>
export default {
    data() {
        return {
            newRating: 0,
            message: '',
        }
    },
    methods: {
        async send() {
            if (this.message.length < 1) {
                this.$toast.error("Te rugăm să completezi mesajul")
                return;
            }

            if (this.newRating == 0) {
                this.$toast.error("Te rugăm să alegi un rating")
                return;
            }

            try {
                let response = await axios.post('clients/submit-feedback', {
                	comment: this.message,
                	rating: this.newRating,
                });
                if (response.data.status != true) {
                    this.$toast.error("Eroare la procesarea datelor");
                    return;
                }
                this.message = '';
                this.newRating = 0;
                this.$toast.success("Feedback trimis");
            } catch (error) {
                if (error.response && error.response.data) {
                    this.$toast.error(error.response.data.human_message[0]);
                } else {
                    this.$toast.error("Eroare la procesarea datelor");
                }
            }
        }
    }
}
</script>