<template>
    <div class="component account-menu" data-aos="fade-right">
        <div class="mobile" v-if="mobile">
            <div class="items">
                <Carousel :items-to-show="3" :wrap-around="false" snapAlign="start">
                    <Slide v-for="link in links" :key="link.name">
                        <router-link :to="link.path" class="nolink">
                            <div class="item" :class="{'active': isActive(link.path)}">
                                <img class="icon not-active" :src="link.icon_active" alt="">
                                <img class="icon active" :src="link.icon_inactive" alt="">
                                {{ link.name }}
                            </div>
                        </router-link>
                    </Slide>
                </Carousel>
            </div>
        </div>
        <div class="desktop" v-if="!mobile">
            <div class="items">
                <router-link :to="link.path" class="nolink" v-for="link in links">
                    <div class="item" :class="{'active': isActive(link.path)}">
                        <img class="icon not-active" :src="link.icon_active" alt="">
                        <img class="icon active" :src="link.icon_inactive" alt="">
                        {{ link.name }}
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
@import "AccountMenu.scss";
</style>
<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
export default {
    name: "AccountMenuComponent",
    props: ['mobile'],
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data() {
        return {
            links: [{
                    name: 'Detalii cont',
                    path: '/app/account/details',
                    icon_active: require('@/assets/images/menu_details.png'),
                    icon_inactive: require('@/assets/images/menu_details_on.png')
                },
                {
                    name: 'Adrese',
                    path: '/app/account/addresses',
                    icon_active: require('@/assets/images/menu_addresses.png'),
                    icon_inactive: require('@/assets/images/menu_addresses_on.png')
                },
                {
                    name: 'Șoferi',
                    path: '/app/account/drivers',
                    icon_active: require('@/assets/images/menu_drivers.png'),
                    icon_inactive: require('@/assets/images/menu_drivers_on.png')
                },
                {
                    name: 'Feedback',
                    path: '/app/account/feedback',
                    icon_active: require('@/assets/images/menu_feedback.png'),
                    icon_inactive: require('@/assets/images/menu_feedback_on.png')
                },
                {
                    name: 'Setări',
                    path: '/app/account/settings',
                    icon_active: require('@/assets/images/menu_settings.png'),
                    icon_inactive: require('@/assets/images/menu_settings_on.png')
                },
                {
                    name: 'Ajutor',
                    path: '/app/account/help',
                    icon_active: require('@/assets/images/menu_help.png'),
                    icon_inactive: require('@/assets/images/menu_help_on.png')
                }
            ]
        }
    },
    methods: {
        isActive(route) {
            return (this.$route.path.includes(route));
        }
    }
}
</script>