<template>
    <div class="view order-history">
        <div class="order-container">
            <div class="order" v-if="order" data-aos="fade">
                <div class="back-mobile d-block d-sm-none d-md-none">
                    <router-link class="nolink" to="/app/history">
                        <img class="arrow" src="@/assets/images/arrow_back.png" alt="">
                    </router-link>
                    <div class="title">
                        {{ order.pickup_address.street_name }} {{ order.pickup_address.street_no }}<br>
                        {{ order.pickup_address.town}}, {{ order.pickup_address.country }}
                    </div>
                </div>
                <div class="back">
                    <router-link class="nolink" to="/app/history">
                        <img src="@/assets/images/arrow_back.png" alt=""><br>
                        Înapoi la comenzi
                    </router-link>
                </div>
                <div class="details custom-scroll">
                    <div class="status">
                        {{ helper.getStatusName(order.status) }}
                    </div>
                    <div class="title d-none d-sm-block">
                        {{ order.pickup_address.street_name }} {{ order.pickup_address.street_no }}<br>
                        {{ order.pickup_address.town}}, {{ order.pickup_address.country }}
                    </div>
                    <div class="time">
                        {{ $moment.format(order.order_date, 'DD MMMM YYYY, hh:mm') }}
                    </div>
                    <div class="driver" v-if="order.driver">
                        <div class="image">
                            <img :src="order.driver.profile_picture_url" alt="">
                        </div>
                        <div class="details">
                            <div class="name">
                                {{ order.driver.firstname }}
                            </div>
                            <div class="car">
                                {{ order.driver.taxi_firm }} {{ order.driver.call_sign }} / {{ order.driver.car_model }}
                            </div>
                            <div class="rating">
                                {{ order.driver.rating}} <img src="@/assets/images/rating_star.png" alt="" >
                            </div>
                        </div>
                        <div class="driver-actions">
                            <v-btn v-if="showRating == false" @click="showRating = true; newRating = null;" class="evaluate second w-100" variant="text">
                                Evaluează șofer
                            </v-btn>
                        </div>
                    </div>
                    <div class="rate" v-if="showRating == true">
                        <div class="stars">
                            <div class="star" :class="{'filled': newRating >= 1}" @click="newRating = 1">
                                <img class="empty" src="@/assets/images/star_empty.png" alt="">
                                <img class="full" src="@/assets/images/star_full.png" alt="">
                            </div>
                            <div class="star" :class="{'filled': newRating >= 2}" @click="newRating = 2">
                                <img class="empty" src="@/assets/images/star_empty.png" alt="">
                                <img class="full" src="@/assets/images/star_full.png" alt="">
                            </div>
                            <div class="star" :class="{'filled': newRating >= 3}" @click="newRating = 3">
                                <img class="empty" src="@/assets/images/star_empty.png" alt="">
                                <img class="full" src="@/assets/images/star_full.png" alt="">
                            </div>
                            <div class="star" :class="{'filled': newRating >= 4}" @click="newRating = 4">
                                <img class="empty" src="@/assets/images/star_empty.png" alt="">
                                <img class="full" src="@/assets/images/star_full.png" alt="">
                            </div>
                            <div class="star" :class="{'filled': newRating >= 5}" @click="newRating = 5">
                                <img class="empty" src="@/assets/images/star_empty.png" alt="">
                                <img class="full" src="@/assets/images/star_full.png" alt="">
                            </div>
                        </div>
                        <div class="rating-actions">
                            <div class="accept" :class="{'disabled': !newRating}" @click="submitDriverRating">
                                <v-icon>mdi-check-circle</v-icon>
                            </div>
                            <div class="cancel" @click="newRating = null; showRating = false;">
                                <v-icon>mdi-close-circle</v-icon>
                            </div>
                        </div>
                    </div>
                    <div class="address">
                        <div class="pickup" v-if="order.pickup_address">
                            <div class="icon">
                                <div class="line" v-if="order.destination_address"></div>
                                <img src="@/assets/images/history_pickup.png" alt="">
                            </div>
                            <div class="text">
                                <div class="label">Ridicare</div>
                                <div class="value">{{ order.pickup_address.street_name }} {{ order.pickup_address.street_no }}</div>
                            </div>
                        </div>
                        <div class="dropoff" v-if="order.destination_address">
                            <div class="icon">
                                <img src="@/assets/images/history_dropoff.png" alt="">
                            </div>
                            <div class="text">
                                <div class="label">Destinație</div>
                                <div class="value">{{ order.destination_address.street_name }} {{ order.destination_address.street_no }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="info" v-if="(order.destination_address && order.destination_address.details) || (order.destination_address && order.destination_address.driver_details || order.card_paid_amount)">
                        <div class="items">
                            <div class="item" v-if="order.destination_address && order.destination_address.details">
                                <div class="label">Detalii Comandă</div>
                                <div class="value">{{ (order.destination_address && order.destination_address.details) ? order.destination_address.details : "-" }}</div>
                            </div>
                            <div class="item" v-if="order.destination_address && order.destination_address.driver_details">
                                <div class="label">Detalii Sofer</div>
                                <div class="value">{{ (order.destination_address && order.destination_address.driver_details) ? order.destination_address.driver_details : "-" }}</div>
                            </div>
                            <div class="item total" v-if="order.card_paid_amount">
                                <div class="label">Preț</div>
                                <div class="value">Total <div class="right">{{ order.card_paid_amount }} RON</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="actions" v-if="order.driver">
                        <v-row>
                            <v-col>
                                <v-btn @click="favoriteDriver" class="evaluate main w-100" variant="text">
                                    <v-icon>mdi-heart</v-icon> &nbsp;
                                    Favorit
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-btn @click="blacklistDriver" class="evaluate second w-100" variant="text">
                                    <v-icon>mdi-cancel</v-icon> &nbsp;
                                    Blochează
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-btn @click="showPhoneDialog = true" class="evaluate second w-100" variant="text">
                                    <v-icon>mdi-phone</v-icon> &nbsp;
                                    Sună șofer
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
                <div class="map"> 
                    <GMapMap :center="{lat: parseFloat(order.pickup_address.latitude), lng: parseFloat(order.pickup_address.longitude)}" :zoom="13" :options="{
                        mapTypeId: 'roadmap',
                        mapId: '711d7654833874ad',
                        mapTypeControl: false,
                        streetViewControl: false,
                        fullscreenControl: false,
                    }">
                        <!-- Pickup Marker -->
                        <GMapMarker v-if="order.pickup_address" icon="/images/marker_pickup.png" :position="{lat: parseFloat(order.pickup_address.latitude), lng: parseFloat(order.pickup_address.longitude)}" />
                        <!-- Destination Marker -->
                        <GMapMarker v-if="order.destination_address" icon="/images/marker_destination.png" :position="{lat: parseFloat(order.destination_address.latitude), lng: parseFloat(order.destination_address.longitude)}" />
                        <!-- Polyline -->
                        <GMapPolyline v-if="polyline" :path="polyline"/>
                    </GMapMap>
                </div>
            </div>
            <div class="loading" v-if="loading">
                <v-progress-circular :size="50" width="6" indeterminate color="primary"></v-progress-circular>
            </div>
        </div>
        <div class="dialogs">
            <v-dialog v-model="showPhoneDialog" width="300px">
                <v-card>
                    <v-card-text class="text-center">
                        {{ order.driver.phone_no }}
                    </v-card-text>
                    <a :href="'tel:' + order.driver.phone_no">
                        <v-btn color="#FFCE09" class="deny w-100">
                            <v-icon>mdi-phone-outline</v-icon> &nbsp;
                            Apelează
                        </v-btn>
                    </a>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import 'OrderHistoryView.scss';
</style>
<script>
import helper from '@/utils/helper.js';
export default {
    name: 'OrderHistoryView',
    computed: {},
    data() {
        return {
            showPhoneDialog: false,
            helper: helper,
            showRating: false,
            newRating: 0,
            order: null,
            loading: false,
            finished: false,
            polyline: null,
        }
    },
    async mounted() {
        await this.getData();
    },
    methods: {
        async submitDriverRating() {
            try {
                let response = await axios.post('clients/review-driver', {
                    driver_id: this.order.driver.driver_id,
                    order_id: this.order.id,
                    rating: this.newRating,
                });
                if(response.data.status == true) {
                    await this.getData();
                    this.$toast.success("Șoferul a fost evaluat");
                    this.newRating = null;
                    this.showRating = false;
                } else {
                    this.$toast.error("Eroare la salvare rating");    
                }
            } catch (error) {
                console.log(error);
                this.$toast.error("Eroare la salvare rating");
            }
        },
        async getData() {
            try {
                this.loading = true;
                let response = await axios.post('clients/get-order', {
                    order_id: this.$route.params.id,
                });
                if (response.data.status == true) {
                    this.order = response.data.data;

                    if(this.order.card_polyline) {
                        this.polyline = helper.decodePolyline(this.order.card_polyline);
                    }
                } else {
                    this.$toast.error("Eroare la incărcarea comenzii.");
                }
            } catch (error) {
                this.$toast.error("Eroare la incărcarea comenzii.");
                console.log(error);
            }
            this.loading = false;
        },
        async favoriteDriver() {
            try {
                let response = await axios.post('clients/add-favorite-driver', {
                    driver_id: this.order.driver.driver_id,
                });
                if (response.data.status == true) {
                    this.$toast.success("Șoferul a fost adaugat la favoriți");
                } else {
                    this.$toast.error("Eroare la adaugarea șoferului la favoriți");
                }
            } catch (error) {
                console.log(error);
                this.$toast.error("Eroare la adaugarea șoferului la favoriți");
            }
        },
        async blacklistDriver() {
            try {
                let response = await axios.post('clients/add-blacklisted-driver', {
                    driver_id: this.order.driver.driver_id,
                });
                if (response.data.status == true) {
                    this.$toast.success("Șoferul a fost adaugat la șoferi blocați");
                } else {
                    this.$toast.error("Eroare la adaugarea șoferului la șoferi blocați");
                }
            } catch (error) {
                console.log(error);
                this.$toast.error("Eroare la adaugarea șoferului la șoferi blocați");
            }
        }
    },
}
</script>