import { createApp } from 'vue';
import { createPinia } from 'pinia';
import VueGoogleMaps from '@fawmi/vue-google-maps'
import Toaster from '@meforma/vue-toaster';
import router from './router/indexRoutes.js';
import vuetify from './config/vuetifyConfig.js';
import AOS from 'aos'
import 'aos/dist/aos.css'
import './config/globalConfig.js';
import './assets/scss/style.scss';


import App from './App.vue';

const pinia = createPinia();
const app = createApp(App);
app.use(pinia)
    .use(router)
    .use(vuetify)
    .use(Toaster, {
        position: 'bottom'
    })
    .use(AOS)
    .use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyCT_OPbslOxmsJieNn58HsbZFmQBQ6os0U',
            language: 'ro',
        },
        autobindAllEvents: true,
    });

// App debug only
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
app.component('json-dump', VueJsonPretty);
import moment from 'moment';
// Global helpers, "filters"
app.config.globalProperties.$moment = {
    moment: moment,
    format(date, format) {
        return moment(date).lang('ro').format(format);
    },
}; 

app.mount('#app');