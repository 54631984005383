import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/authStore.js';

import LoginView from '@/views/Auth/Login/LoginView.vue';
import AppView from '@/views/App/AppView.vue';
import LiveView from '@/views/App/Live/LiveView.vue';
import OrderHistoryView from '@/views/App/History/Order/OrderHistoryView.vue';
import OrdersHistoryView from '@/views/App/History/Orders/OrdersHistoryView.vue';
import AccountView from '@/views/App/Account/AccountView.vue';

import AccountAddressesView from '@/views/App/Account/Addresses/AddressesView.vue';
import AccountDetailsView from '@/views/App/Account/Details/DetailsView.vue';
import AccountDriversView from '@/views/App/Account/Drivers/DriversView.vue';
import AccountDriversFavoritesView from '@/views/App/Account/Drivers/Favorites/DriversFavoritesView.vue';
import AccountDriversBlacklistedView from '@/views/App/Account/Drivers/Blacklisted/DriversBlacklistedView.vue';
import AccountFeedbackView from '@/views/App/Account/Feedback/FeedbackView.vue';
import AccountHelpView from '@/views/App/Account/Help/HelpView.vue';
import AccountSettingsView from '@/views/App/Account/Settings/SettingsView.vue';


const routes = [
    {
        path: '/',
        redirect: '/app/live',
    },
    { 
        path: '/login',
        name: 'LoginView',
        component: LoginView,
        meta: { requiresGuest: true },
    },
    {
        path: '/app',
        name: 'AppView',
        component: AppView,
        meta: { requiresAuth: true }, 
        children: [
            // Default redirect
            {
                path: '',
                redirect: '/app/live',
            },
            // Live
            {
                path: 'live',
                name: 'LiveView',
                component: LiveView,
            },
            // History
            {
                path: 'history',
                name: 'OrdersHistoryView',
                component: OrdersHistoryView,
            },
            {
                path: 'history/:id',
                name: 'OrderHistoryView',
                component: OrderHistoryView,
            },
            // Account
            {
                path: 'account',
                name: 'AccountView',
                component: AccountView,
                children: [
                    {
                        path: '',
                        redirect: '/app/account/details',
                    },
                    {
                        path: 'addresses',
                        name: 'Addresses',
                        component: AccountAddressesView,
                    },
                    {
                        path: 'details',
                        name: 'Details',
                        component: AccountDetailsView,
                    },
                    {
                        path: 'drivers',
                        name: 'Drivers',
                        component: AccountDriversView,
                        children: [
                            {
                                path: '',
                                redirect: '/app/account/drivers/favorites',
                            },
                            {
                                path: 'favorites',
                                component: AccountDriversFavoritesView,
                            },
                            {
                                path: 'blacklisted',
                                component: AccountDriversBlacklistedView,
                            }
                        ]
                    },
                    {
                        path: 'feedback',
                        name: 'Feedback',
                        component: AccountFeedbackView,
                    },
                    {
                        path: 'help',
                        name: 'Help',
                        component: AccountHelpView,
                    },
                    {
                        path: 'settings',
                        name: 'Settings',
                        component: AccountSettingsView,
                    }
                ]
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

/**
 * Auth Middleware
 */
router.beforeEach((to, from, next) => {
    const authStore = useAuthStore();
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!authStore.loggedIn) {
            next({
                path: '/login',
            })
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.requiresGuest)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (authStore.loggedIn) {
            next({
                path: '/',
            })
        } else {
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
})

export default router