<template>
    <div class="component autocomplete-address">
        <v-autocomplete :placeholder="label" class="autocomplete-address custom" ref="autocomplete" :multiple="false" no-filter hide-details clearable hide-no-data return-object v-model="selected" v-model:search="search" item-title="name" item-props :loading="loading" :items="items"  :readonly="(selected != null)" @click:clear="handleCleared" @focus="handleFocus" :menu-icon="null" :persistent-clear="true" variant="solo" :menu-props="{closeOnContentClick:true}">
            <template v-slot:item="{ props, item }">
                <!-- Normal Address -->
                <v-list-item class="autocomplete-list-item" v-bind="props"  :title="item?.raw?.name" :subtitle="item?.raw?.info" 
                v-if="item?.raw?.is_favorite == 0"
                :prepend-avatar="icons.address"></v-list-item>
                <!-- Home -->
                <v-list-item class="autocomplete-list-item" v-bind="props"  title="Acasă" :subtitle="item?.raw?.info" 
                v-if="item?.raw?.is_favorite == 1 && item?.raw?.name == 'Home'"
                :prepend-avatar="icons.home"></v-list-item>
                <!-- Work -->
                <v-list-item class="autocomplete-list-item" v-bind="props"  title="Muncă" :subtitle="item?.raw?.info" 
                v-if="item?.raw?.is_favorite == 1 && item?.raw?.name == 'Work'"
                :prepend-avatar="icons.work"></v-list-item>
                <!-- Favorites -->
                <v-list-item class="autocomplete-list-item" v-bind="props"  :title="item?.raw?.name" :subtitle="item?.raw?.info" 
                v-if="item?.raw?.is_favorite == 1 && item?.raw?.name != 'Work' && item?.raw?.name != 'Home'"
                :prepend-avatar="icons.favorite"></v-list-item>
            </template>
            <template v-slot:prepend-item >
                <div @click="handleSelectFromMap" class="autocomplete-select-from-map" v-if="showMap">
                    <img class="icon" v-if="type=='pickup'" src="@/assets/images/marker_pickup.png" alt="">
                    <img class="icon" v-if="type=='extraDestination'" src="@/assets/images/marker_dropoff.png" alt="">
                    <img class="icon" v-if="type=='destination'" src="@/assets/images/marker_dropoff.png" alt="">
                    <div class="text">Alege locația pe hartă</div>
                </div>
            </template>
        </v-autocomplete>
    </div>
</template>
<style lang="scss">
@import 'AutocompleteAddress.scss';
</style>
<script>
import { useAuthStore } from '@/stores/authStore.js';
import { mapStores } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import starTaxiPlaces from '@/utils/starTaxiPlaces.js';
export default {
    name: "AutocompleteAddress",
    props: ['label', 'type', 'showMap', 'apiType', 'extraDestinationIndex'],
    data() {
        return {
            loading: false,
            selected: null,
            items: [],
            search: null,
            bounceTimeout: null,
            icons: {
                address: require("@/assets/images/dropdown_address.png"),
                home: require("@/assets/svg/icon_home.svg"),
                work: require("@/assets/svg/icon_work.svg"),
                favorite: require("@/assets/svg/icon_star.svg"),
            }
        }
    },
    computed: {
        ...mapStores(useAuthStore),
    },
    watch: {
        search(query) {
            if (this.selected == this.search) {
                return;
            }
            clearTimeout(this.bounceTimeout);
            if (!query || query.length <= 3) {
                this.loading = false;
                return;
            }
            this.loading = true;
            this.bounceTimeout = setTimeout(() => {
                this.getData(query);
            }, 200);
        },
        selected(newValue) {
            if (newValue) {
                newValue = { ...newValue }; // resolve proxy
            }

            if(newValue == null) {
                this.$emit('select', null);
                return;
            }

            if(newValue.latitude && newValue.longitude) {
                // Location is in our system
                this.$emit('select', newValue);
                return;
            } 

            // Resolve new location
            this.resolveUnkownLocation(newValue);
            
            //this.$emit('select', newValue);
        }
    },
    async mounted() {
        await this.getDefaultData();
    },
    methods: {
        async resolveUnkownLocation(location) {
            console.log('resolving unkown location...', location);
            try {
                let updatedLocation = await starTaxiPlaces.resolveUnkown(location);
                console.log('resolved location', updatedLocation);
                this.$emit('select', updatedLocation);
            } catch (error) {
                console.log(error);
                this.$toast.error('Eroare rezolvare adresa');
            }
        },
        async handleSelectFromMap() {
            if(this.type != "extraDestination") {
                this.$emit('clickMap', this.type);
            } else {
                this.$emit('clickMap', {
                    type: this.type,
                    index: this.extraDestinationIndex,
                })
            }
        },
        async handleFocus(event) {
            this.$emit('focus');
            try {
                this.items = await starTaxiPlaces.searchEmpty(this.apiType);
            } catch (error) {
                console.log(error);
                this.$toast.error('Eroare rezolvare adresa');
            }
        },
        async handleCleared() {
            this.selected = null;
            this.search = null;
            this.items = [];

            await this.getDefaultData();
        },
        async getDefaultData() {
            try {
                this.items = await starTaxiPlaces.searchEmpty(this.apiType);
            } catch (error) {
                console.log(error);
                this.$toast.error('Eroare rezolvare adresa');
            }
        },
        async getData(query) {
            try {
                this.items = await starTaxiPlaces.search(query, this.apiType);
            } catch (error) {
                this.$toast.error('Eroare rezolvare adresa');
            }
            this.loading = false;
        },
        selectAddress(place) {
            this.selected = place;
        }
    }
}
</script>